import { type GeoLocation } from "@src/appV2/Location";
import { convertMetersToMiles, convertToGeoLocation } from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { round } from "lodash";

import { useGetDrivingDistance } from "../GeoLocation/useGetDrivingDistance";

interface UseDistanceToWorkplaceProps {
  workplaceGeoLocation: GeoLocation;
}

function formatDistance(distanceInMiles: number): string {
  if (distanceInMiles < 1) {
    return "<1 mi from home";
  }

  // Using lodash to round to 1 decimal place, i.e. "1.2 mi" instead of "1.23 mi".
  // Math.round() would round to the nearest integer, i.e. "1 mi" instead of "1.2 mi".
  return `${round(distanceInMiles, 1)} mi from home`;
}

export function useDistanceToWorkplace(props: UseDistanceToWorkplaceProps) {
  const { workplaceGeoLocation } = props;

  const worker = useDefinedWorker();
  const workerCoordinates = worker.geoLocation?.coordinates;

  const { data, ...rest } = useGetDrivingDistance({
    origin: workplaceGeoLocation
      ? { latitude: workplaceGeoLocation.latitude, longitude: workplaceGeoLocation.longitude }
      : undefined,
    destination: workerCoordinates ? convertToGeoLocation(workerCoordinates) : undefined,
  });

  const formattedDistance = data
    ? formatDistance(convertMetersToMiles(data.distance.value))
    : undefined;

  return {
    ...rest,
    data,
    formattedDistance,
    // Because the query can be disabled in case worker or facility geoLocation is not defined,
    // we need to use always the isInitialLoading instead of isLoading.
    // Otherwise, it will be loading forever even if the query is disabled.
    // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
    isLoading: rest.isInitialLoading,
  };
}
