import { type UseGetQueryOptions } from "@src/appV2/api/useGetQuery";
import {
  type FacilityNotesResponse,
  useFacilityNotes,
} from "@src/appV2/Facilities/api/useFacilityNotes";
import { type FacilityNote } from "@src/appV2/Facilities/types";

import { type WorkplaceNoteIdentifier } from "./constants";

type WorkplaceNotesRecord = Partial<Record<WorkplaceNoteIdentifier, FacilityNote>>;

export function useWorkplaceNotesParsed(
  workplaceId: string,
  options: UseGetQueryOptions<FacilityNotesResponse> = {}
) {
  const query = useFacilityNotes(workplaceId, options);

  const parsedData: WorkplaceNotesRecord | undefined = query.data?.reduce<WorkplaceNotesRecord>(
    (accumulator, note) => {
      if (note.identifier) {
        accumulator[note.identifier as WorkplaceNoteIdentifier] = note;
      }

      return accumulator;
    },
    {}
  );

  return {
    ...query,
    data: parsedData,
  };
}
