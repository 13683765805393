import { Stack } from "@mui/material";

interface WorkplaceReviewCommentRepliesAndLikesWrapperProps {
  children: React.ReactNode;
}

export function WorkplaceReviewCommentRepliesAndLikesWrapper(
  props: WorkplaceReviewCommentRepliesAndLikesWrapperProps
) {
  const { children } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
      sx={{ height: "fit-content" }}
    >
      {children}
    </Stack>
  );
}
