import { usePostComment } from "@src/appV2/Reviews/api/usePostComment";
import { getWorkplaceCommentsQueryKey } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { DialogFooter } from "@src/appV2/ShiftDiscovery/components/DialogFooter";
import { IconButton } from "@src/appV2/ShiftDiscovery/components/IconButton";
import { Input } from "@src/appV2/ShiftDiscovery/components/Input";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { updateCommentListCacheWithRepliesCount } from "./queryCache";

interface WorkplaceCommentPostReplyFooterProps {
  workplaceId: string;
  parentId: string;
}

export function WorkplaceCommentPostReplyFooter(props: WorkplaceCommentPostReplyFooterProps) {
  const { workplaceId, parentId } = props;

  const [text, setText] = useState("");

  const queryClient = useQueryClient();

  const { mutate: postComment, isLoading: isPostingComment } = usePostComment(workplaceId, {
    onSuccess: async () => {
      setText("");

      // refetch the replies of the parent comment
      await queryClient.invalidateQueries({
        queryKey: getWorkplaceCommentsQueryKey({
          workplaceId,
          filter: { parentCommentId: parentId },
        }),
      });

      updateCommentListCacheWithRepliesCount({
        queryClient,
        parentCommentId: parentId,
        workplaceId,
      });
    },
  });

  return (
    <DialogFooter withShadow orientation="horizontal">
      <Input
        placeholder="Post a comment..."
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
      />
      <IconButton
        iconType="arrow-up"
        variant="contained"
        disabled={!text || isPostingComment}
        onClick={() => {
          postComment({ text, parentId });
        }}
      />
    </DialogFooter>
  );
}
