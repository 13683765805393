import { isDefined } from "@clipboard-health/util-ts";
import { type GetCommentResponse } from "@src/appV2/Reviews/api/useGetComment";
import { getCommentUrl } from "@src/appV2/Reviews/api/usePatchComment";
import {
  getWorkplaceCommentsQueryKey,
  type WorkplaceCommentAttributesType,
  type WorkplaceCommentType,
} from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";
import { type QueryClient } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";

interface UpdateCommentListCacheWithReactionParams {
  queryClient: QueryClient;
  commentId: string;
  workplaceId: string;
  reaction: WorkplaceCommentAttributesType["workerReaction"];
}

interface UpdateCommentRepliesListCacheWithReactionParams
  extends UpdateCommentListCacheWithReactionParams {
  parentCommentId: string;
}

interface UpdateCommentListCacheWithRepliesCountParams {
  queryClient: QueryClient;
  parentCommentId: string;
  workplaceId: string;
}

/**
 * This updates the query data for the workplace comments.
 * It updates the workerReaction and the totalLikes count.
 * It might be a subject to change if we decide to use pagination for the comments.
 */
export function updateCommentListCacheWithReaction({
  queryClient,
  commentId,
  workplaceId,
  reaction,
}: UpdateCommentListCacheWithReactionParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey({ workplaceId }),
    (data) => {
      if (!isDefined(data)) {
        return data;
      }

      const commentIndex = data.findIndex((comment) => comment.id === commentId);
      if (commentIndex === -1) {
        return data;
      }

      const newData = cloneDeep(data);
      const { attributes } = newData[commentIndex];
      const { totalLikes } = attributes.aggregates;

      attributes.workerReaction = reaction;
      attributes.aggregates.totalLikes = totalLikes + (reaction === Reaction.LIKE ? 1 : -1);

      return newData;
    }
  );
}

/**
 * This updates the query data for the comment replies.
 * It updates the workerReaction and the totalLikes count.
 */
export function updateCommentRepliesListCacheWithReaction({
  queryClient,
  commentId,
  workplaceId,
  reaction,
  parentCommentId,
}: UpdateCommentRepliesListCacheWithReactionParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey({ workplaceId, filter: { parentCommentId } }),
    (data) => {
      if (!isDefined(data)) {
        return data;
      }

      const commentIndex = data.findIndex((comment) => comment.id === commentId);
      if (commentIndex === -1) {
        return data;
      }

      const newData = cloneDeep(data);
      const { attributes } = newData[commentIndex];
      const { totalLikes } = attributes.aggregates;

      attributes.workerReaction = reaction;
      attributes.aggregates.totalLikes = totalLikes + (reaction === Reaction.LIKE ? 1 : -1);

      return newData;
    }
  );
}

/**
 * This updates the query data for the single comment.
 * It updates the workerReaction and the totalLikes count.
 */
export function updateSingleCommentCacheWithReaction({
  queryClient,
  commentId,
  workplaceId,
  reaction,
}: UpdateCommentListCacheWithReactionParams) {
  const queryKey = [getCommentUrl({ workplaceId, commentId }), null];

  queryClient.setQueryData<GetCommentResponse>(queryKey, (oldResponse) => {
    if (!isDefined(oldResponse)) {
      return oldResponse;
    }

    const newResponse = cloneDeep(oldResponse);

    const { attributes } = newResponse.data;
    const { totalLikes } = attributes.aggregates;

    attributes.workerReaction = reaction;
    attributes.aggregates.totalLikes = totalLikes + (reaction === Reaction.LIKE ? 1 : -1);

    return newResponse;
  });
}

export function updateCommentListCacheWithRepliesCount({
  queryClient,
  parentCommentId,
  workplaceId,
}: UpdateCommentListCacheWithRepliesCountParams) {
  queryClient.setQueryData<WorkplaceCommentType[]>(
    getWorkplaceCommentsQueryKey({ workplaceId }),
    (data) => {
      if (!isDefined(data)) {
        return data;
      }

      const commentIndex = data.findIndex((comment) => comment.id === parentCommentId);
      if (commentIndex === -1) {
        return data;
      }

      const newData = cloneDeep(data);
      newData[commentIndex].attributes.aggregates.totalReplies += 1;

      return newData;
    }
  );
}
